<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="d-flex flex-row"
      >
        <search-left-section class="hidden-sm-and-down" />
        <search-feed-section />
        <agency-right-section
          class="hidden-md-and-down"
          v-if="profileType == 'Agency'"
        />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AgencyRightSection from "../agency/AgencyRightSection.vue";
import SearchFeedSection from "./SearchFeedSection.vue";
import SearchLeftSection from "./SearchLeftSection.vue";
export default {
  components: {
    AgencyRightSection,
    SearchFeedSection,
    SearchLeftSection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: [],
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
    }),
  },
};
</script>
<style scoped></style>
